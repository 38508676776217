import { useEffect, useState, useCallback, ChangeEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import { checkViewPermission } from "../../../utils/permissions";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../../store";
import { Container, Grid, Tab } from "@mui/material";
import TabTittle from "../../../components/Text/TabTittle";
import PaginationComponent from "../../../components/Pagination";
import NoPermission from "../../../components/Erros/NoPermission";
import SESSIONSTORAGE from "../../../helpers/constants/sessionStorage";
import { toast } from "react-toastify";
import Table from "../../../components/Table";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import {
  loadRequest,
  loadRequestCreateMeet,
} from "../../../store/ducks/telemedicineAttendances/actions";
import {
  ITelemedicineAttendances,
  ITelemedicineAttendancesRequestParams,
} from "../../../store/ducks/telemedicineAttendances/types";
import SignaturePassword from "../../../components/SignaturePassword";
import SearchComponent from "../../../components/List/Search";
import React from "react";
import AutocompleteField from "../../../components/FormFields/AutocompleteField";
import { useForm } from "react-hook-form";

export default function TelemedicineList() {
  const params = useParams();
  const navigate = useNavigate();
  // console.log(`listType1: ${params.type}`);
  const integration = sessionStorage.getItem(SESSIONSTORAGE.INTEGRATION);
  const dispatch = useDispatch();
  const [valueTab, setValueTab] = useState(params.type === 'I' ? "1" : params.type === 'A' ? "2" : "0");
  const [attendanceType, setAttendanceType] = useState(params.type || "U");
  const [page, setPage] = useState<string | number>("1");
  const [limit, setLimit] = useState<string | number>("10");
  const [refetch, setRefetch] = useState(false);
  const [errorSearch, setErrorSearch] = useState({ value: false });
  const [search, setSearch] = useState("");
  const [collumns, setCollumns] = useState<any[]>([]);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {

    let attendanceType = "U";

    if (newValue === "0") {
      setAttendanceType("U");
      attendanceType = "U";
    } else if (newValue === "1") {
      setAttendanceType("I");
      attendanceType = "I";
    }else if (newValue === "3") {
      setAttendanceType("F");
      attendanceType = "F";
    }else {
      setAttendanceType("A");
      attendanceType = "A";
    }
    setValueTab(newValue);
    navigate(`/telemedicine/${attendanceType}`);
  };

  const listPayload: ITelemedicineAttendancesRequestParams = {
    attendance_type: attendanceType,
    patient_name: search,
    limit,
    page,
  };
  // console.log(`listPayload: ${JSON.stringify(listPayload)}`);
  useEffect(() => {
    if (search.length > 3 || search.length === 0) {
      dispatch(loadRequest(listPayload));
      setRefetch(false);
    }
  }, [attendanceType, search, page, limit, refetch]);

  const telemedicineAttendancesState = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances
  );
  telemedicineAttendancesState.attendanceType = params.type

  const rightsOfLayoutState = useSelector(
    (state: ApplicationState) => state.layout.data.rights
  );

  const digitalSignatureState = useSelector(
    (state: ApplicationState) => state.digitalSignature
  );

  const handleSearchInput = useCallback((event: any) => {
    // listPayload.patient_name = event;
    // console.log(`searchPayload: ${JSON.stringify(listPayload)}`);
    // console.log(`listTypeDentroDaHandle: ${params.type}`);
    // dispatch(loadRequest(listPayload));
    // setRefetch(false);
  }, []);

  const handleChangeInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(event.target.value);
    if (event.target.value.length > 3) {
      setErrorSearch((prev) => ({
        ...prev,
        value: false,
      }));
    } else {
      setErrorSearch((prev) => ({
        ...prev,
        value: true,
      }));
    }

    if (event.target.value === "") {
      handleSearchInput("");
    }
  };

  const handleKeyEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchInput(search);
    }
  };

  const handleClickSearch = (e: any) => {
    handleSearchInput(search);
  };

  const handleCreateRoom = (telemedicine: ITelemedicineAttendances) => {
    navigate(`/telemedicine/${telemedicine.scheduling_id}/A/overview`);
  };

  useEffect(() => {
    if (valueTab === "0") {
      setCollumns([
        { name: "Atendimento", align: "left" },
        { name: "Paciente", align: "left" },
        { name: "Data de Nascimento", align: "center" },
        { name: "Médico Assistente", align: "left" },
        { name: "Especialidade", align: "center" },
        { name: "Data do Atendimento", align: "center" },
        { name: "Status", align: "center" },
      ]);
    } else if (valueTab === "1") {
      setCollumns([
        { name: "Atendimento", align: "left" },
        { name: "Paciente", align: "left" },
        { name: "Data de Nascimento", align: "center" },
        { name: "Médico Assistente", align: "left" },
        { name: "Especialidade", align: "center" },
        { name: "Data da Solicitação", align: "center" },
        { name: "Status", align: "center" },
      ]);
    } else if (valueTab === "2") {
      setCollumns([
        { name: "Atendimento", align: "left" },
        { name: "Paciente", align: "left" },
        { name: "Data de Nascimento", align: "center" },
        { name: "Médico Assistente", align: "left" },
        { name: "Especialidade", align: "center" },
        { name: "Data da Solicitação", align: "center" },
        { name: "Status", align: "center" },
      ]);
    }

  }, [attendanceType]);

  // const { handleSubmit, control, reset } = useForm({
  //   defaultValues: {
  //     response: "",
  //     realization_type: "AVALIAÇÃO",
  //     alerts: null,
  //     describe: null
  //   },
  // });


  return (
    <Sidebar>
      {checkViewPermission(
        "telemedicine",
        JSON.stringify(rightsOfLayoutState)
      ) ? (
        <Container>
          <SignaturePassword open={digitalSignatureState.isOpen} />
          <TabTittle tittle="Telemedicina" />
          {integration ? (
            <>
              <TabContext value={valueTab}>
                <TabList
                  onChange={handleChangeTab}
                  scrollButtons="auto"
                  sx={{ marginLeft: 3 }}
                >
                  <Tab label={"Urgência"} id={`tab-${valueTab}`} value="0" />
                  <Tab label={"Internação"} id={`tab-${valueTab}`} value="1" />
                  <Tab label={"Ambulatório"} id={`tab-${valueTab}`} value="2" />
                  {/* <Tab label={"Finalizados"} id={`tab-finished`} value="3" /> */}
                </TabList>
                <TabPanel value={valueTab}>
                    <Grid display={"flex"}>
                      <Grid mr={2}>
                        <SearchComponent
                          handleButton={() => null}
                          inputPlaceholder="Pesquise pelo nome do paciente"
                          onChangeInput={handleChangeInput}
                          value={search}
                          onKeyEnter={handleKeyEnter}
                          onClickSearch={handleClickSearch}
                        />
                      </Grid>
                      {/* <AutocompleteField
                        control={control}
                        name="attendance_typr"
                        label="Tipo de Atendimento"
                        options={[
                          { id: "U", name: "Urgência" },
                          { id: "I", name: "Internação" },
                          { id: "A", name: "Ambulatório" },
                        ]}
                        optionLabelKey={"name"}
                        customOnChange={() => setValue("specialties", [])}
                        required
                      /> */}
                    </Grid>
                  <Table
                    tableCells={collumns}
                    actions={attendanceType == "A" ? true : false}
                    actionHandleCopy={() => {
                      toast.success("Link copiado com sucesso!");
                    }}
                    actionHandleJoinMeet={(telemedicine: ITelemedicineAttendances) => handleCreateRoom(telemedicine)}
                    telemedicineAttendanceState={telemedicineAttendancesState}
                    actionsTypes={["copy", "meet"]}
                  >
                    {""}
                  </Table>
                </TabPanel>
              </TabContext>
            </>
          ) : (
            <>
              <Table
                tableCells={[
                  { name: "Atendimento", align: "left" },
                  { name: "Paciente", align: "left" },
                  { name: "Data de Nascimento", align: "center" },
                  { name: "Médico Assistente", align: "left" },
                  { name: "Especialidade", align: "center" },
                ]}
              >
                {""}
              </Table>
            </>
          )}
          <PaginationComponent
            page={String(page)}
            rowsPerPage={String(limit)}
            totalRows={Number(telemedicineAttendancesState.data.total || 0)}
            handleFirstPage={() => setPage("1")}
            handleLastPage={() =>
              dispatch(
                loadRequest({
                  page: Math.ceil(
                    +telemedicineAttendancesState.data.total / +limit
                  ).toString(),
                  limit: limit.toString(),
                  ...listPayload,
                })
              )
            }
            handleNextPage={() => setPage(Number(page) + 1)}
            handlePreviosPage={() => setPage(Number(page) - 1)}
            handleChangeRowsPerPage={(event) => setLimit(event.target.value)}
          />
        </Container>
      ) : (
        <NoPermission />
      )}
    </Sidebar>
  );
}
