import React, { useState, useRef, useEffect, useCallback } from "react";
import { CircularProgress, Box, Typography, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { loadRequestCreateConsentTerm } from '../../../../store/ducks/telemedicineAttendances/actions';
interface dataProps {
  data: {
    patient: {
      name: string;
      document: string;
    };
    doctor: {
      name: string;
      document: string;
    }
  }
  onConfirm: () => void;
  onRefuse: () => void;
}
  const getMonthName = (monthIndex) => {
    const months = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];
    return months[monthIndex];
  };

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = getMonthName(currentDate.getMonth());
  const year = currentDate.getFullYear();

  const formattedDate = `São Paulo, ${day} de ${month} de ${year}`;

const consentForm: React.FC<dataProps> = ({data, onConfirm, onRefuse}) => {
  const dispatch = useDispatch();
  const [accept, setAccept] = useState(false)
  const [isAccepted, setIsAccepted] = useState<boolean | null>(null)
  const [ip, setIp] = useState("")
  const consentTermState = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances.consentTerm
  );

  const guestState = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances.guest
  );

  const handleGetIp = useCallback(
      async () => {
        await fetch('https://geolocation-db.com/json/')
        .then(response => response.json())
        .then(data => {
          setIp(data.IPv4)
        })
        .catch(error => console.log(error))
        // navigate(`/login`)
      },
      []
    );

  handleGetIp()

  const handleSubmit = (isAccepted: boolean) => {
    setAccept(true)
    setIsAccepted(isAccepted)
    dispatch(loadRequestCreateConsentTerm({
      patient_name: guestState.patient.name,
      patient_document: guestState.patient.document,
      doctor_name: guestState.doctor.name,
      doctor_document: guestState.doctor.document,
      address_footer: guestState.address_footer,
      address_header: guestState.address_header,
      age: guestState.age,
      birth_date: guestState.birth_date,
      gender: guestState.gender,
      health_insurance: guestState.health_insurance,
      mother_name: guestState.mother_name,
      origin: guestState.origin,
      telemedicine_attendance_id: guestState.telemedicine_attendance_id,
      isAccepted,
      ip
    }))

  }

  useEffect(() => {
    if (consentTermState) {
      if (isAccepted === true){
        onConfirm()
      }else if (isAccepted === false){
        onRefuse()
      }
    }else{
      setAccept(false)
    }
  }, [consentTermState])

  return (
    <Box sx={{ margin: "auto", p: 2 }}>
    <Typography variant="body1" gutterBottom>
      Eu, <u> {data.patient.name} </u>, dou autorização ao Dr.(a){" "}
      <u>{data.doctor.name}</u>, inscrito(a) no CRM-SP sob o nº{" "}
      <u>{data.doctor.document}</u> para realizar o atendimento através de serviços eletrônicos
      de consulta à distância.
    </Typography>

    <Typography variant="h6" sx={{ mt: 2 }}>
      Declaro ter ciência que:
    </Typography>

    {[
      "O atendimento a distância possui algumas limitações.",
      "Para o bom aproveitamento e análise do Médico devo informar TODOS os dados possíveis, não omitindo nenhuma informação acerca dos meus sinais e sintomas.",
      "A precisão das minhas informações se faz necessária em virtude da ausência de avaliação clínica presencial pelo Médico.",
      "Poderá ser necessária a complementação com exames para um melhor diagnóstico.",
      "Poderá ser necessária a minha presença ao consultório para realização de exame clínico, a critério do médico.",
      "Poderei ser encaminhada, segundo a avaliação do médico, para um serviço de saúde.",
      "A consulta eletrônica é pontual e não garante ao Paciente o direito ao atendimento por tempo indeterminado ou à disposição do médico em outros horários não acordados previamente entre as partes.",
      "Autorizo, ainda, a gravação dessa consulta, que passará a fazer parte de meu prontuário.",
    ].map((item, index) => (
      <Typography key={index} variant="body1" sx={{ ml: 2, textIndent: "-1rem", display: "block" }}>
        <b>({index + 1})</b> {item}
      </Typography>
    ))}

    <Typography variant="body1" sx={{ mt: 2 }}>
      Por fim, declaro ter lido as informações e orientações contidas no presente instrumento, as quais entendi
      perfeitamente e aceito.
    </Typography>

    <Typography variant="body1" sx={{ mt: 2 }}>
      Assim, expresso meu pleno consentimento para a realização da teleconsulta.
    </Typography>

    <Typography variant="body1" sx={{ mt: 4 }}>
      {formattedDate}
    </Typography>

    <Typography variant="body1" sx={{ mt: 2 }}>
      <u>{data.patient.name}</u> <br />
      {/* (Nome Paciente) <br /> */}
      RG n.º <u>{data.patient.document}</u>
    </Typography>

    {!accept && (
      <Grid spacing={2} display={"flex"} sx={{ mt: 2 }}>
        <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => {
              handleSubmit(true);
            }}
          >
            Li e concordo com os termos
        </Button>
        <Button
            variant="contained"
            color="error"
            fullWidth
            sx={{ mt: 2, ml: 2 }}
            onClick={() => {
              handleSubmit(false);
            }}
          >
            Não concordo
        </Button>
      </Grid>
    )}
  </Box>
  );
};

export default consentForm;
