import React, { useState, useRef, useEffect } from "react";
import { TextField, Button, Snackbar, Alert, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loadRequestGuestData } from '../../../../store/ducks/telemedicineAttendances/actions';
import { ApplicationState } from "../../../../store";
interface CpfConfirmationProps {
  // cpf: string; // CPF completo ex: "12345678909"
  room_id: string;
  onConfirm: () => void; // Callback quando a confirmação for bem-sucedida
}

const CpfConfirmation: React.FC<CpfConfirmationProps> = ({ room_id, onConfirm }) => {
  const [digits, setDigits] = useState(["", "", ""]);

  const [error, setError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [cpf, setCpf] = useState('');
  const dispatch = useDispatch();
  const inputRefs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];

  const guestData = useSelector(
      (state: ApplicationState) => state.telemedicineAttendances.guest
    );

  // Obtém os 3 primeiros dígitos do CPF
  useEffect(() => {
    setError(false);
    if (guestData){
      if (digits.join("") === guestData?.patient?.fiscal_number?.slice(0, 3)) {
        setError(false);
        setOpenSnackbar(true);
        onConfirm();
      } else {
        if (digits[0].length > 0 || digits[1].length > 0 || digits[2].length > 0) setError(true);
      }
    }
  }, [guestData])

  const handleChange = (index: number, value: string) => {
    if (!/^\d?$/.test(value)) return; // Permite apenas um número por campo

    const newDigits = [...digits];
    newDigits[index] = value;
    setDigits(newDigits);

    // Move para o próximo campo automaticamente
    if (value && index < 2) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent) => {
    if (event.key === "Backspace" && !digits[index] && index > 0) {
      inputRefs[index - 1].current?.focus();
    }
  };

  const handleSubmit = () => {
    dispatch(loadRequestGuestData({
      room_id,
      token:  '12345',//process.env.REACT_APP_TOKEN_GUEST_TELEMEDICINE
    }))
    // setCpf(guestData.patient.document)
  };

  return (
    <Box>
      <Typography variant="h5" fontWeight={700} color="primary" gutterBottom>
        Para começar precisamos confirmar seu CPF
      </Typography>
      <Typography variant="body1" gutterBottom>
        Digite os <Typography component="span" fontSize={20} fontWeight={500}>3 primeiros</Typography> dígitos:
      </Typography>
      <Typography variant="h5" sx={{ fontFamily: "monospace", mb: 2 }}>
        <span style={{ fontWeight: "bold", fontSize: "2rem", color: "primary" }}>***</span>.***.***-**
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 1, mb: 2 }}>
        {digits.map((digit, index) => (
          <TextField
            key={index}
            inputRef={inputRefs[index]}
            type="number"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            variant="outlined"
            autoComplete="off"
            inputProps={{
              maxLength: 1,
              style: { textAlign: "center", fontSize: "1.5rem", width: "3rem" },
            }}
            error={error}
          />
        ))}
      </Box>
      {error && <Typography color="error">Dígitos incorretos, tente novamente.</Typography>}

      {!openSnackbar &&
        // <Alert severity="success">
        //   Confirmação realizada com sucesso, estamos te redirecionando...
        // </Alert>
      (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleSubmit}
          disabled={digits.some((d) => d === "")}
        >
          Confirmar
        </Button>
      )}

      {/* <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
        <Alert severity="success" onClose={() => setOpenSnackbar(false)}>
          CPF confirmado com sucesso!
        </Alert>
      </Snackbar> */}
    </Box>
  );
};

export default CpfConfirmation;
