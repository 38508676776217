import { Grid, Typography } from "@mui/material";
import React from "react";

export default function patientDataOverview({
  patientName,
  birthDate,
  motherName,
}: {
  patientName: string;
  birthDate: string;
  motherName: string;
}) {
  return (
    <Grid item xs={12}>
      <Typography fontSize={16} fontWeight={550} mb={0.5} textAlign="right">
        Paciente: {patientName}
      </Typography>
      <Typography fontSize={13} mb={1} textAlign="right">
        Nascimento: {birthDate}
      </Typography>
      <Typography fontSize={13} mb={1} textAlign="right">
        Mãe: {motherName}
      </Typography>
    </Grid>
  );
}
