import React, { useState, useEffect } from "react";
import { CircularProgress, LinearProgress, Box, Typography, Button } from "@mui/material";
import { useSocket } from "../../../../utils/useSocket";
import { ITelemedicineAttendancesGuestDataResponse } from '../../../../store/ducks/telemedicineAttendances/types';
interface dataProps {
  data: {
    roomId: string;
    attendance: ITelemedicineAttendancesGuestDataResponse
  }
  onExit: () => void;
  onDoctorInRoom: () => void;
  onDoctorOutRoom: () => void;
}

const waitingTelemedicine: React.FC<dataProps> = ({data, onExit, onDoctorInRoom,  onDoctorOutRoom}) => {

  const socket = useSocket(data.roomId);
  const [message, setMessage] = useState({ raw: "", date: "" });
  // const [messages, setMessages] = useState<string[]>([]);

  useEffect(() => {
    if (!socket) return;
    // console.log(`socket`)
    socket.on("telemedicine-guest", (newMessage) => {
      // setMessages((prevMessages) => [...prevMessages, newMessage]);
      // console.log(newMessage)
      const data = JSON.parse(newMessage) as { raw: string, date: string };
      setMessage(data);
    });

    return () => {
      socket.off("telemedicine-guest");
    };
  }, [socket]);

  useEffect(() => {
    // console.log(message)
    if (message.raw === "doctor_in_room") {
      onDoctorInRoom();
    }
    // else if (message.raw === "doctor_out_room") {
    //   onDoctorOutRoom();
    // }
  }, [message]);

  return (
    <Box>

      <Typography variant="h5" fontWeight={700} color="primary" gutterBottom>

        {message.raw === "doctor_in_room" ? (
          'O MÉDICO ESTÁ PRONTO PRA TE ATENDER'
        ) : (
          'AGUARDANDO A ENTRADA DO MÉDICO NA SALA'
        )}
      </Typography>
      <Typography variant="h6" color="primary" gutterBottom>
        Olá {data.attendance.patient.name}
      </Typography>
      <Typography variant="h6" color="primary" gutterBottom>
      {message.raw === "doctor_in_room" ? (
          `Estamos preparando o atendimento com o Dr. ${data.attendance.doctor.name}`
        ) : (
          'Seu médico entrará na sala em breve. Por favor aguarde!'
        )}
      </Typography>

      {/* <Typography variant="h6" color="primary" gutterBottom>
        {messages.map((msg, index) => (
          <p key={index}>{msg}</p>
        ))}
      </Typography> */}

      {message.raw === "doctor_in_room" ? (
        // <CheckIcon fontSize="large" color="success"/>
        <LinearProgress></LinearProgress>
      ) : (
        <>
          <CircularProgress size={46}/>
          <Button
          variant="contained"
          color="error"
          fullWidth
          sx={{ mt: 2 }}
          onClick={onExit}
          // disabled={digits.some((d) => d === "")}
        >
          Sair da sala de espera
        </Button>
      </>
      )}
    </Box>

  );
};

export default waitingTelemedicine;
