import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const SOCKET_TOKEN = process.env.REACT_APP_SOCKET_TOKEN;

export const useSocket = (id?: string, role?: string) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {

    const configSocket: any = {
      extraHeaders: {
        Authorization: `Bearer ${SOCKET_TOKEN}`
      },
      query: {
        // id,
        role: role ?? 'guest'
      }
    }

    if (!role) configSocket.query.id = id

    const socketInstance = io(SOCKET_URL, configSocket);

    socketInstance.on("connect", () => {
      // console.log("🟢 Conectado ao WebSocket");
    });

    socketInstance.on("disconnect", () => {
      // console.log("🔴 Desconectado do WebSocket");
    });

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
  }, []);

  return socket;
};
